import emicio from "./images/emicio.png"
import felipe from "./images/felipe.png";
import igor from "./images/igor.png"
// import aqui a nova imagem

const data = [
   {
     img: felipe,
     name: "Dr. Felipe Quintão",
     text: "Médico Veterinário  e Cirurgião."
   },
   {
      img: emicio,
      name: "Dr. Emício Quintão",
      text: "Médico Veterinário, Ortopedista , Cirurgião e Dermatologista."
    },
    {
      img: igor,
      name: "Dr. Igor Quintão",
      text: "Médico Veterinário e Anestesista."
    },
    // {
    //   img: imagem-da-pessoa, obs: importar no inicio do arquivo
    //   name: "Dr. Felipe Quintão",
    //   text: "Médico Veterinário  e Cirurgião." // texto de descrição
    // },
];

export default data;