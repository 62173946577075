import React, { useEffect, useState } from "react";
import "./css/styleTeam.scss";
import CarouselTeam from "./Carousel-team";
import ModalContact from './modal-contact';
import BtnModal from './modal-contact/btn-custom-modal';
import { getUrl, whatsappData } from '../services/whatsapp/msg';
import { serviceContact } from './service/utils/msg';

function Team() {
  const [modalContact, setModalContact] = useState(false);
  const toggleModalContact = () => setModalContact(!modalContact);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => { // refactor border plate
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const isMobile = (size, limit) => {
    return size < limit;
  }
  return (
    <section id="section-team" className="container-section-team">
      <div className="container-text">
        <h2>Nossa equipe</h2>
        {!isMobile(windowWidth, 880) && <p>A Clivet Quintão além de contar com uma excelente e completa infraestrutura, conta também com uma equipe especializada e completa em diferentes áreas de atuação veterinária para atender ao seu pet!</p>}
      </div>
      <CarouselTeam />
      <div className="container-btn-team">
        <button onClick={toggleModalContact}>Agendar uma consulta</button>
      </div>
      <ModalContact text={serviceContact.schedule.msg} isOpen={modalContact} toggle={toggleModalContact} >
        <BtnModal link={getUrl(whatsappData.patinga.phone, "Olá tudo bem?")} text="Ipatinga" />
        <BtnModal link={getUrl(whatsappData.fabriciano.phone, "Olá tudo bem?")} text="Coronel Fabriciano" />
        <BtnModal link={getUrl(whatsappData.timoteo.phone, "Olá tudo bem?")} text="Timóteo" />
      </ModalContact>
    </section>
  )
}

export default Team;