import React, { useState } from 'react';
import './css/styleService.scss';
import ModalContact from './modal-contact';
import BtnModal from './modal-contact/btn-custom-modal';
import { serviceContact } from './service/utils/msg';
import { getUrl, whatsappData } from '../services/whatsapp/msg';
import HeaderContact from './header/utils/msg';

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';

import pet1 from '../assets/petImages/pet1.png'
import pet2 from '../assets/petImages/pet2.png'
import pet3 from '../assets/petImages/pet3.png'

const items = [
  {
    src: pet1,
    altText: '24 horas cuidando de quem cuida de você!',
    caption: '24 horas cuidando de quem cuida de você!',
    key: 1,
  },
  {
    src: pet2,
    altText: '24 horas cuidando de quem cuida de você!',
    caption: '24 horas cuidando de quem cuida de você!',
    key: 2,
  },
  {
    src: pet3,
    altText: '24 horas cuidando de quem cuida de você!',
    caption: '24 horas cuidando de quem cuida de você!',
    key: 3,
  },
];


function Service(args) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [modalContact, setModalContact] = useState(false);
  const toggleModalContact = () => setModalContact(!modalContact);
  const [messageCardContact, setMessageCardContact] = useState("Escolha uma unidade");

  const haddleClickContact = (msg, func) => {
    setMessageCardContact(msg);
    func();
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });

  return (
    <section id="section-service">
      <div className="image-background">
        <div className='container-pet'>
          <div className="imagePet"></div>
          <div className="container-text">
            <h2>Clivet Quintão</h2>
            <h3>Somos a clínica veterinária aberta 24 horas para você</h3>
            <div className='container-sub-text'>
              <div className="medal"><p>+ de 30 anos no mercado</p></div>
              <div className='iconPeople'><p>Somos especialistas em diversas áreas</p></div>
              <div className='iconFli'><p>Laboratório interno com alta tecnologia</p></div>
              <div className='iconHome'><p>Internação com segurança e cuidado</p></div>
            </div>
          </div>
        </div>
        <Carousel
          activeIndex={activeIndex}
          ride='carousel'
          next={next}
          previous={previous}
          {...args}
        >
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>
        <div className="selectIcons">
          <div onClick={() => haddleClickContact(serviceContact.schedule.msg, toggleModalContact)} className="container-btn-service iconPaw">Agendar consulta</div>
          <div onClick={() => haddleClickContact(serviceContact.exame.msg, toggleModalContact)} className="container-btn-service iconTestTube">Resultado de exame</div>
          <div onClick={() => haddleClickContact(serviceContact.vaccine.msg, toggleModalContact)} className="container-btn-service iconSyringe">Agendar vacinação</div>
        </div>
      </div>
      <ModalContact text={messageCardContact} isOpen={modalContact} toggle={toggleModalContact} >
        <BtnModal link={getUrl(whatsappData.patinga.phone, "Olá tudo bem?")} text="Ipatinga" />
        <BtnModal link={getUrl(whatsappData.fabriciano.phone, "Olá tudo bem?")} text="Coronel Fabriciano" />
        <BtnModal link={getUrl(whatsappData.timoteo.phone, "Olá tudo bem?")} text="Timóteo" />
      </ModalContact>
      <p className='serviceSection'>Nossos serviços</p>
      <div className='container-service'>
        <div className='container-icons'>
          <div onClick={() => haddleClickContact(HeaderContact.comercial, toggleModalContact)}><div className='icon1'></div><p>Testes e exames</p></div>
          <div onClick={() => haddleClickContact(HeaderContact.comercial, toggleModalContact)} className='content1'><div className='icon2'></div><p>Internação</p></div>
        </div>
        <div className='container-icons'>
          <div onClick={() => haddleClickContact(HeaderContact.comercial, toggleModalContact)}><div className='icon3'></div><p>Raio X</p></div>
          <div onClick={() => haddleClickContact(HeaderContact.comercial, toggleModalContact)} className='content2'><div className='icon4'></div><p>Cirurgias</p></div>
        </div>
        <div className='container-icons'>
          <div onClick={() => haddleClickContact(HeaderContact.comercial, toggleModalContact)}><div className='icon5'></div><p>Vacinação</p></div>
          <div onClick={() => haddleClickContact(HeaderContact.comercial, toggleModalContact)} className='content3'><div className='icon6'></div><p>Especialistas</p></div>
        </div>
      </div>
    </section>
  )
}

export default Service;