const whatsappData = {
  patinga: {
    phone: "5531986481032"
  },
  fabriciano: {
    phone: "5531993980498"
  }, 
  timoteo: {
    phone: "5531986481036"
  }
}

const getUrl = (phone, msg) => {
  return `https://api.whatsapp.com/send/?phone=${phone}&text=${msg}&type=phone_number&app_absent=0`;
}

export {
  getUrl,
  whatsappData
}