import post1 from "./images/post1.jpg";
import post2 from "./images/post2.jpg";
import post3 from "./images/post3.jpg";

const data = [
   {
     img: post1,
     title: "Leve seu pet para o exterior",
     text: "Quer levar seu animalzinho para os Eua e não sabe como?! Entre em contato conosco!!!",
     url: "https://www.instagram.com/p/CiG4J2_NLnS/"
   },
   {
      img: post2,
      title: "A importância da vacina antirrábica",
      text: "🩺A raiva é uma doença que pode ser transmitida pela saliva de cães, gatos, morcegos, bovinos e equi...",
      url: "https://www.instagram.com/p/CKRUOumjUm2/"
    },
    {
      img: post3,
      title: "Babesia, doença causada por carrapatos",
      text: "🩺Babesiose é uma doença causada por um gênero de protozoários denominado Babesia spp que pa...",
      url: "https://www.instagram.com/p/CKhDPXvjrFl/?img_index=1"
    },
  //  {
  //    img: post3,
  //    title: "Babesia, doença causada por carrapatos",
  //    text: "🩺Babesiose é uma doença causada por um gênero de protozoários denominado Babesia spp que pa...",
  //    url: "https://www.instagram.com/p/CKhDPXvjrFl/?img_index=1"
  //  },
];

export default data;