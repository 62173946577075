import React from 'react';
import starFill from '../assets/feedback/ratingStar.svg'
import starUnfill from '../assets/feedback/ratingStarUnfill.svg'

const RatingDisplay = ({ rating, w, h }) => {
  const MAX_RATING = 5; // Altere para o número máximo de estrelas desejado

  // Função para criar as estrelas com base na classificação
  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < MAX_RATING; i++) {
      if (i < rating) {
        stars.push(<img key={i} src={starFill} alt="star" style={ { width: w+'px', height:h+'px' } } />);
      } else {
        stars.push(<img key={i} src={starUnfill} alt="star" style={ { width: w+'px', height: h+'px' } }/>);
      }
    }
    return stars;
  };

  return (
    <div className='rating-stars' style={ { height: h + 'px', width: `${w * MAX_RATING}px` } }>
        {renderStars()}
    </div>
  );
};

export default RatingDisplay;
