import React from 'react';
import './css/styleFeedback.scss';
import RatingDisplay from './rating-display-comp';

const FeedbackComp = ({ message, rating, name, description, imgSrc, caption, faded, invisible }) => {

  return (
    <div className={`feedback-card${faded ? ' faded' : ''}${invisible ? ' invisible' : ''}`}>
      <div className='img-container'>
        <img className='img-circle' src={imgSrc} alt={caption} />
      </div>
      <div className='content-container'>
        <p className='message subtitle1 black-color'>{message}</p>
        <RatingDisplay rating={rating} w={ 20 } h={ 20 } />
        <div className='name-container'>
          <div className='text-align'>
            <h2 className='name description-and-name'>{name}</h2>
            <h2 className='description description-and-name'>{description}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackComp;
