import React, { useState, useEffect, useRef } from "react";
import "./css/styleCarouselInstagram.scss";
import { motion } from "framer-motion"
import data from "../mock/instagram/data-instagram"
import { v4 as uuidv4 } from 'uuid';


function CarouselInstagram () {
  const carousel = useRef();
  const [width, setWidth] = useState(0)
  useEffect(() => {
   setWidth(((carousel.current?.scrollWidth - carousel.current?.offsetWidth) + 0.1)) // todo (0.1) -> refactor finally carousel
  }, [])

  return (
    <motion.div ref={carousel} className="carousel" whileTap={{ cursor: "grabbing"}}>
      <motion.div className="inner" drag="x" dragConstraints={{right: 0, left: -width}}>
        {
          data.map((el) => {
            return (
              <motion.div  key={uuidv4()} className="item">
                <img src={el.img} alt="alter" />
                <div  key={uuidv4()} className="info" >
                <h4>{el.title}</h4>
                <p>{el.text}</p>
                <span><a href={el.url}>+ Ler matéria completa</a></span>
                </div>
              </motion.div>
            )
          })
        }
      </motion.div>
    </motion.div>
  )
}

export default CarouselInstagram;