import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ContainerBtn from './container-btn';

function ModalContact({ isOpen, children, text, toggle }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const isMobile = (size, limit) => {
    return size < limit;
  }

  return (
    <div>
      <Modal centered={true} className={isMobile(windowWidth, 880) ? 'modal-sm' : 'modal-custom-width modal-lg'} isOpen={isOpen} toggle={toggle}>
        <ModalHeader className='border-bottom-0' toggle={toggle}></ModalHeader>
        <ModalBody className='d-flex ml-4 flex-column'>
          <p className='pt-4 fs-4 text-modal'>{text}</p>
          <br />
          <ContainerBtn>
            {
              children
            }
          </ContainerBtn>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ModalContact;