// https://reactrouter.com/en/main/start/tutorial
// Separate routing configuration object for improved readability of new page registrations.
import Home from "../pages/home/index"
import ErrorPage from "../pages/error/error-page";
const config = [
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
]

export default config;