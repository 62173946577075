import React, { useEffect, useState } from "react";
import CarouselInstagram from "./Carousel-instagram";
import "./css/stylePublicationInsta.scss";
import imageSocialMediaFacebook from "../assets/socialMedia/facebook.svg";
import imageSocialMediaInstagram from "../assets/socialMedia/instagram.svg";
import ModalContact from './modal-contact';
import BtnModal from './modal-contact/btn-custom-modal';
import { getUrl, whatsappData } from '../services/whatsapp/msg';
import { serviceContact } from './service/utils/msg';

function Publication() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [modalContact, setModalContact] = useState(false);
  const toggleModalContact = () => setModalContact(!modalContact);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = (size, limit) => {
    return size < limit;
  }

  if (isMobile(windowWidth, 880)) {
    return (
      <section id="section-publi" className="container-section">
        <h2 className="title">Publicações Clivet Quintão</h2>
        <CarouselInstagram />
        <div id="section-contact" className="container-contact">
          <button onClick={toggleModalContact}>Agendar uma consulta</button>
        </div>
        <ModalContact text={serviceContact.schedule.msg} isOpen={modalContact} toggle={toggleModalContact} >
          <BtnModal link={getUrl(whatsappData.patinga.phone, "Olá tudo bem?")} text="Ipatinga" />
          <BtnModal link={getUrl(whatsappData.fabriciano.phone, "Olá tudo bem?")} text="Coronel Fabriciano" />
          <BtnModal link={getUrl(whatsappData.timoteo.phone, "Olá tudo bem?")} text="Timóteo" />
        </ModalContact>
      </section>
    )
  }

  return (
    <section id="section-publi" className="container-section">
      <h2 className="title">Publicações Clivet Quintão</h2>
      <CarouselInstagram />
      <div className="social-media">
        <p>Acompanhe nossas redes</p>
        <div className="images-social-media">
          <a href="https://www.facebook.com/clivetquintaooficial"><img src={imageSocialMediaFacebook} alt="logo facebook" /></a>
          <a href="https://www.instagram.com/clivetquintao24h/"><img src={imageSocialMediaInstagram} alt="logo instagram" /></a>
        </div>
      </div>
      <h2 className="contact">Contato</h2>
      <div id="section-contact" className="iconPhone">Ligue para 31. 3821-8400</div>
    </section>
  )
}

export default Publication;