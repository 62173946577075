import "./style.scss";

const ContainerBtn = ({children}) => {
  return (
      <div className="container-btn-custom">
        {children}
      </div>
  )
}

export default ContainerBtn;