import React, { useEffect, useState } from 'react';
import './css/styleFeedback.scss';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
} from 'reactstrap';
import FeedbackComp from './feedback-comp';
import img1 from '../assets/feedback/img1.svg';
import FeedbackCompMobile from './feedback-comp-mobile';

const feedbackItems = [
  {
    src: img1,
    caption: 'Slide 1',
    message: '‘’Atendimento rápido. Fácil fazer contato com a clínica. Serviço de vacinação em domicílio excelente! Tenho um cãozinho nada fácil e lidaram da melhor forma possível.’’',
    rating: 5,
    name: 'Natália Prado',
    description: 'Tutora do Floquinho'
  },
  {
    src: img1,
    caption: 'Slide 2',
    message: 'Ótima clínica, sempre muito bem atendida. A auxiliar Ana é a melhor 💜 ...',
    rating: 5,
    name: 'Millena Pegorettii',
    description: 'Tutora do blink'
  },
  {
    src: img1,
    caption: 'Slide 3',
    message: 'Atenderam rapidamente via whatsapp, chegamos e também fomos atendidos prontamente na clínica.',
    rating: 5,
    name: 'Walter Carvalho',
    description: 'Tutora do Omega'
  },
  {
    src: img1,
    caption: 'Slide 4',
    message: 'Fomos bem atendida na Clínica. A minha Juju passou por um exame de ultrassom abdominal e está tudo bem. Coisas da idade. 15 anos.',
    rating: 5,
    name: 'Helena Gonçalves',
    description: 'Tutora do Juju'
  },
  {
    src: img1,
    caption: 'Slide 5',
    message: 'Excelente local para seu pet, profissionais dedicados e inteligentes, além de uma estrutura de altíssima qualidade!! O melhor atendimento que um pet pode receber... Recomendo',
    rating: 5,
    name: 'Davi Cunha',
    description: 'Tutora do Milita'
  },
  {
    src: img1,
    caption: 'Slide 6',
    message: 'Ótimo atendimento para vc e seu Pet. Profissionais de alto nível e espaço físico muito bom',
    rating: 5,
    name: 'Luciano Bragança',
    description: 'Tutora do Zeus'
  }
]

const FeedbackSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function isMobile() {
    return windowWidth < 881;
  }
  
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === feedbackItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const slides = feedbackItems.map((item, index, arr) => {
    return (
      <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={item.src}
      className={`${(index === arr.length - 1) ? ' last' : ''}`}
      >
        <FeedbackComp message={ item.message } rating={ item.rating } name={ item.name } description={ item.description }  imgSrc={ item.src } caption={ item.caption }/>
        {
          index+1 < arr.length 
          &&(<FeedbackComp
            message={ arr[index+1].message }
            rating={ arr[index+1].rating }
            name={ arr[index+1].name }
            description={ arr[index+1].description }
            imgSrc={ arr[index+1].src }
            caption={ arr[index+1].caption }
            faded={true}
            invisible={animating ? true : false}
            />)
        }
      </CarouselItem>
    );
  });

  

  return (
    <section className="feedback-section" id='section-depoiments'>
      <h2 className={ isMobile() ? 'mobile-title' : 'desktop-title' }>Depoimentos</h2>
      {isMobile() ? (
      <div className='feedback-mobile w-100'>
        { feedbackItems.map((item, index) => (
          <div key={index} className={ ((index % 2) === 0 ) ? 'first' : 'second'}>
            <FeedbackCompMobile item={ item } key={ index } />
          </div>
        ))}
      </div>
      ) : (
      <Carousel
      activeIndex={activeIndex}
      next={next}
      interval={false}
      >
      {slides}
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
      </Carousel>
      )}
    </section>
  );
};

export default FeedbackSection;
