import React from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import './css/styleLocationCard.scss';
import {getUrl} from '../services/whatsapp/msg';


const LocationCard = ({ title, address, status, url, img, phone }) => {
  return (
    <Card className="card-item">
      <CardBody>
        <a className="map-thumbnail" href={url} style={ {
          backgroundImage: `url(${img})`
        } }>
          {/* Here you would include your embedded map or an image */}
        </a>
        <div className="card-content">
          <h2>{title}</h2>
          <p className='subtitle1 dark-color'>{address}</p>
          <p className="status subtitle1">{status}</p>
        </div>
        <Button className='btn-success button-color1'><a className='link-contact' href={getUrl(phone, "Olá tudo bem?")}>Entrar em contato</a></Button>
      </CardBody>
    </Card>
  );
};

export default LocationCard;
