import React, { useState } from 'react';
import './css/styleCarouselOurUnitsLocsMob.scss';
import {
  CarouselItem,
  CarouselControl,
  Carousel,
} from 'reactstrap';

import CarouselOurUnitsImgsMob from './Carousel-ourunits-imgs-mob';

function CarouselUnitsLocationMob( { itenst, items } ) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === itenst.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? itenst.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const slides = itenst.map((item, index) => {
    return (
      <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={index}
      className={ index === activeIndex ? 'carousel-item active' : 'carousel-item' }
      >
        <div className="card-content">
          <h2 className='subtitle1 black-color'>{item.title}</h2>
          <a href={item.url} className='w-100'>
          <img className='carousel-image-loc' src={ item.img } alt="" />
          </a>
          <p className='body2 black-color'>{item.address}</p>
          <p className="status highlight-color3">{item.status}</p>
        </div>
      </CarouselItem>
    );
  });
  
  return (
    <section className='section-our-units' id='section-uni'>
      <div className="carousel-units-locs-mobile">
        <Carousel
          activeIndex={activeIndex}
          next={next}
          interval={false}
          previous={() => {}}
          >
          {slides}
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
            />
        </Carousel>
        <CarouselControl
          direction="prev"
          onClickHandler={previous}
          />
        <CarouselControl
          direction="next"
          onClickHandler={next}
        />
      </div>
      <CarouselOurUnitsImgsMob content={items}
      />
    </section>
  );
}



export default CarouselUnitsLocationMob;
