import React, { useState, useEffect, useRef } from "react";
import "./css/styleCarouselTeam.scss";
import { motion } from "framer-motion"
import data from "../mock/team/data"
import { v4 as uuidv4 } from 'uuid';


function CarouselTeam () {
  const carousel = useRef();
  const [width, setWidth] = useState(0)
  useEffect(() => {
   setWidth(((carousel.current?.scrollWidth - carousel.current?.offsetWidth) + 0.1)) // todo (0.1) -> refactor finally carousel
  }, [])

  return (
    <motion.div id="section-team" ref={carousel} className="carousel-team" whileTap={{ cursor: "grabbing"}}>
      <motion.div className="inner-team" drag="x" dragConstraints={{right: 0, left: -width}}>
        {
          data.map((el) => {
            return (
              <motion.div  key={uuidv4()} className="item-team">
                <img src={el.img} alt="alter" />
                <div  key={uuidv4()} className="info-team" >
                <h4>{el.name}</h4>
                <p>{el.text}</p>
                </div>
              </motion.div>
            )
          })
        }
      </motion.div>
    </motion.div>
  )
}

export default CarouselTeam;