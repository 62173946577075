import React, { useState } from 'react';
import './css/styleAbout.scss';
import clinicImage from "../assets/about/clinic-mobile.svg";
import iconAwardsPath from "../assets/about/awards.svg";
import iconExpPath from "../assets/about/experience.svg";
import iconClockPath from "../assets/about/clock.svg";

import ModalContact from './modal-contact';
import BtnModal from './modal-contact/btn-custom-modal';
import { getUrl, whatsappData } from '../services/whatsapp/msg';
import { serviceContact } from './service/utils/msg';

const AboutMobile = ({ title, description, buttonText }) => {
  const [modalContact, setModalContact] = useState(false);
  const toggleModalContact = () => setModalContact(!modalContact);
  return (
    <section id='section-about' className="clinic-section-mobile">
      <div className='container-green'>
        <div className='content-container'>
          <div className="info-container">
            <h2 className="title">{title}</h2>
            <p className="description">{description}</p>

            <p className='p'>Procuramos sempre renovar nossa estrutura e serviços:</p>

            <ul className="services">
                <li>Internação confortável</li>
                <li>Laboratório interno</li>
                <li>Raio-X digital</li>
                <li>Estacionamento</li>
              </ul>
            {/* Mobile-specific additional content */}
          </div>
          <div className="image-wrapperr">
            <img alt="Clinic" className="clinic-image-mobile" src={clinicImage} />
          </div>
        </div>
      </div>
      <div className='container-white'>
        <div className='container-additional-info'>
          <div className="additional-info">
            <div className="info-item">
              {/* Replace 'iconAwards' with the actual path to your awards icon */}
              <img src={iconAwardsPath} alt="Awards" className="info-icon" />
              <span className="info-text">Veterinário do ano!</span>
            </div>
            <div className="info-item">
              {/* Replace 'iconExperience' with the actual path to your experience icon */}
              <img src={iconExpPath} alt="Experience" className="info-icon" />
              <span className="info-text">+ de 30 anos no mercado</span>
            </div>
            <div className="info-item">
              {/* Replace 'iconClock' with the actual path to your 24/7 icon */}
              <img src={iconClockPath} alt="24/7" className="info-icon" />
              <span className="info-text">24 horas abertos para você!</span>
            </div>
          </div>
        </div>
        <div className="buttons-container">
          <button onClick={toggleModalContact} className="btn-about">{buttonText}</button>
        </div>
      </div>
      <ModalContact text={serviceContact.schedule.msg} isOpen={modalContact} toggle={toggleModalContact} >
        <BtnModal link={getUrl(whatsappData.patinga.phone, "Olá tudo bem?")} text="Ipatinga" />
        <BtnModal link={getUrl(whatsappData.fabriciano.phone, "Olá tudo bem?")} text="Coronel Fabriciano" />
        <BtnModal link={getUrl(whatsappData.timoteo.phone, "Olá tudo bem?")} text="Timóteo" />
      </ModalContact>
    </section>
  );
};

export default AboutMobile;
