import React, { useState } from "react";
import "./css/styleFooter.scss";
import logo from "../assets/header/logo.svg";
import facebook from "../assets/socialMedia/facebook.svg";
import instagram from "../assets/socialMedia/instagram.svg";
import ModalContact from './modal-contact';
import BtnModal from './modal-contact/btn-custom-modal';
import { getUrl, whatsappData } from '../services/whatsapp/msg';
import { serviceContact } from './service/utils/msg';

function Footer() {
  const [modalContact, setModalContact] = useState(false);
  const toggleModalContact = () => setModalContact(!modalContact);
  return <footer className="footer-container">
    <div className="content-footer-container">
      <div className="container-logo-footer">
        <img src={logo} alt="logo clivet quintão" />
        <p>Há 35 anos cuidando de<br /> quem cuida de você!</p>
      </div>
      <div className="divisor-content"></div>
      <ul className="container-menu-footer">
        <li><a href="#section-service">Serviços e especialidades</a></li>
        <li><a href="#section-about">Sobre nós</a></li>
        <li><a href="#section-team">Nossa equipe</a></li>
        <li><a href="#section-publi">Publicações</a></li>
        <li><a href="#section-depoiments">Depoimentos</a></li>
        <li><a href="#section-uni">Unidades</a></li>
        <li onClick={toggleModalContact}><a href="#section-contact">Contato</a></li>
      </ul>
      <div className="container-social-media-footer">
        <p>Acompanhe nossas redes</p>
        <div className="container-social-media-group">
          <a href="https://www.facebook.com/clivetquintaooficial"><img src={facebook} alt="logo facebook" /></a>
          <a href="https://www.instagram.com/clivetquintao24h/"><img src={instagram} alt="logo instagram" /></a>
        </div>
        <p>Contato</p>
        <p>clivetquintao@gmail.com</p>
        <p>(31) 3821-8400</p>
      </div>
    </div>
    <p>2023 Clivet Quintão - Todos os direitos reservados</p>
    <ModalContact text={serviceContact.schedule.msg} isOpen={modalContact} toggle={toggleModalContact} >
      <BtnModal link={getUrl(whatsappData.patinga.phone, "Olá tudo bem?")} text="Ipatinga" />
      <BtnModal link={getUrl(whatsappData.fabriciano.phone, "Olá tudo bem?")} text="Coronel Fabriciano" />
      <BtnModal link={getUrl(whatsappData.timoteo.phone, "Olá tudo bem?")} text="Timóteo" />
    </ModalContact>
  </footer>
}

export default Footer;