const serviceContact = {
  schedule: {
    msg: "Você quer agendar uma consulta em qual unidade?"
  },
  exame: {
    msg: "Seu animal realizou o exame em qual unidade?"
  },
  vaccine: {
    msg: "Você quer agendar vacinação em qual unidade?"
  }
}

export {
  serviceContact
};