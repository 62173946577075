import { Button } from 'reactstrap';
import logo from '../assets/header/logo.svg'
import "./css/styleHeader.scss"
import { useState, useEffect } from 'react';
import React from 'react';
import ModalContact from './modal-contact';
import BtnModal from './modal-contact/btn-custom-modal';
import HeaderContact from './header/utils/msg';
import { whatsappData, getUrl } from '../services/whatsapp/msg';

function Header() {
  // 24 horas
  const [modal24Hrs, setModal24Hrs] = useState(false);
  const toggleModal24Hrs = () => setModal24Hrs(!modal24Hrs);
  // horario comercial
  const [modalContact, setModalContact] = useState(false);
  const toggleModalContact = () => setModalContact(!modalContact);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [btnActive, setBtnActive] = useState("Início");
  const menuOpen = "nav-list-open";
  const menuClose = "nav-list-close";

  const [active, setActive] = useState(false);

  const selectBtn = (value) => {
    setBtnActive(value);
  }

  const alterMenu = () => {
    setActive(!active);
  };

  const getClassSelect = (e) => {
    return e === btnActive ? 'select' : '';
  }

  const getClassSelectWeb = (e) => {
    return e === btnActive ? 'navWebSelect' : '';
  }

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = (size, limit) => {
      return size < limit;
  }
 
  if (isMobile(windowWidth, 880)) {
    return (
      <div className='containerMob'>
      <header id='section-init'>
        <div onClick={alterMenu} className="menu-hamburguer">
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
        <h1><img src={logo} alt="logo da empresa" className='logoBob' /></h1>
      </header>
      <div className='container-list'>
        <ul className={(active ? menuOpen : menuClose)}>
          <li className='containerBtnClose'>
          <div onClick={alterMenu} className="menu-close">
          <div className="line-close1"></div>
          <div className="line-close2"></div>
          <div className="line-close3"></div>
        </div>
          </li>
          <li className={getClassSelect("Início")} onClick={alterMenu}><a onClick={() => selectBtn("Início")} href="#section-service">Início</a></li>
          <li className={getClassSelect("especialidades")} onClick={alterMenu}><a href="#section-service" onClick={() => selectBtn("especialidades")}>Serviços e especialidades</a></li>
          <li className={getClassSelect("Sobre")} onClick={alterMenu}><a href="#section-about" onClick={() => selectBtn("Sobre")}>Sobre nós</a></li>
          <li className={getClassSelect("Equipe")} onClick={alterMenu}><a href="#section-team" onClick={() => selectBtn("Equipe")}>Equipe</a></li>
          <li className={getClassSelect("Depoimentos")} onClick={alterMenu}><a href="#section-depoiments" onClick={() => selectBtn("Depoimentos")}>Depoimentos</a></li>
          <li className={getClassSelect("Conteúdos")} onClick={alterMenu}><a href="#section-publi" onClick={() => selectBtn("Conteúdos")}>Conteúdos Pet</a></li>
          <li className={getClassSelect("Unidade")} onClick={alterMenu}><a href="#section-uni" onClick={() => selectBtn("Unidade")}>Unidade</a></li>
          <li className={getClassSelect("Contato")} onClick={alterMenu}><a href="#section-contact" onClick={() => selectBtn("Contato")}>Contato</a></li>
        </ul>
        </div>
    </div>
    )
  }

  return (
    <>
      <header className='containerWeb' id='section-init'>
      <img className='logoWeb' src={logo} alt="logo da empresa" />
       <div className='containerBTN'>
      <Button onClick={ toggleModal24Hrs } className='btn_l'>Plantão 24hrs</Button>
      <Button onClick={toggleModalContact} className='btn_w'>Fale conosco</Button>
      </div>
    </header>
    <nav className='navWeb'>
      <ul>
        <li className={getClassSelectWeb("Início")} ><a onClick={() => selectBtn("Início")} href="#section-service">Início</a></li>
        <li className={getClassSelectWeb("Serviços")}><a onClick={() => selectBtn("Serviços")} href="#section-service">Serviços</a></li>
        <li className={getClassSelectWeb("Sobre")}><a onClick={() => selectBtn("Sobre")} href="#section-about">Sobre nós</a></li>
        <li className={getClassSelectWeb("Equipe")}><a onClick={() => selectBtn("Equipe")} href="#section-team">Equipe</a></li>
        <li className={getClassSelectWeb("Unidades")}><a onClick={() => selectBtn("Unidades")} href="#section-uni">Unidades</a></li>
        <li className={getClassSelectWeb("Depoimentos")}><a onClick={() => selectBtn("Depoimentos")} href="#section-depoiments">Depoimentos</a></li>
        <li className={getClassSelectWeb("Publicações")}><a onClick={() => selectBtn("Publicações")} href="#section-publi">Publicações</a></li>
        <li className={getClassSelectWeb("Contato")}><a onClick={() => selectBtn("Contato")} href="#section-contact">Contato</a></li>
      </ul>
    </nav>
    <ModalContact text={HeaderContact.Hrs} isOpen={modal24Hrs} toggle={toggleModal24Hrs}>
      <BtnModal link={getUrl(whatsappData.patinga.phone, "Olá tudo bem?")} text="Ipatinga"/>
      <BtnModal link={getUrl(whatsappData.fabriciano.phone, "Olá tudo bem?")} text="Coronel Fabriciano"/>
    </ModalContact>
    <ModalContact text={HeaderContact.comercial} isOpen={modalContact} toggle={toggleModalContact} >
      <BtnModal link={getUrl(whatsappData.patinga.phone, "Olá tudo bem?")} text="Ipatinga"/>
      <BtnModal link={getUrl(whatsappData.fabriciano.phone, "Olá tudo bem?")} text="Coronel Fabriciano"/>
      <BtnModal link={getUrl(whatsappData.timoteo.phone, "Olá tudo bem?")} text="Timóteo"/>
    </ModalContact>
    </>
  )
}

export default Header;