import React, { useEffect, useState } from 'react';
import clinicImage from '../assets/about/clinic.svg';
import AboutMobile from './AboutMobile';
import ModalContact from './modal-contact';
import BtnModal from './modal-contact/btn-custom-modal';
import { getUrl, whatsappData } from '../services/whatsapp/msg';
import { serviceContact } from './service/utils/msg';

import './css/styleAbout.scss';

const About = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [modalContact, setModalContact] = useState(false);
  const toggleModalContact = () => setModalContact(!modalContact);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = (size, limit) => {
      return size < limit;
  }
  if (isMobile(windowWidth, 880)) return (
    <AboutMobile
    title={"Sobre a Clivet Quintão"}
    description="A Clivet Quintão está há mais de 30 anos cuidando dos animais do Vale do Aço. Nosso objetivo é tratar nossos clientes com ética e compromisso."
    buttonText="Agendar uma consulta"/>
  )
  return (
    <section id='section-about' className="clinic-section">
      <div className="containerzao row d-flex flex-nowrap">
          <div className="content-wrapper">
            <h2 className="title-about1 title4">Sobre a clínica</h2>
            <p className="description">A Clivet Quintão está há mais de 30 anos cuidando dos animais do Vale do Aço. Nosso objetivo é tratar nossos clientes com ética e compromisso.</p>
            <p>Procuramos sempre renovar nossa estrutura e serviços:</p>
            <ul className="services">
              <li>Internação confortável</li>
              <li>Laboratório interno</li>
              <li>Raio-X digital</li>
              <li>Estacionamento</li>
            </ul>
            <button onClick={toggleModalContact} className="btn-custom">Agendar uma consulta</button>
          </div>
          <div className="image-wrapperr">
            <img src={clinicImage} alt="Clinic" className="clinic-image" />
          </div>
      </div>
      <ModalContact text={serviceContact.schedule.msg} isOpen={modalContact} toggle={toggleModalContact} >
        <BtnModal link={getUrl(whatsappData.patinga.phone, "Olá tudo bem?")} text="Ipatinga" />
        <BtnModal link={getUrl(whatsappData.fabriciano.phone, "Olá tudo bem?")} text="Coronel Fabriciano" />
        <BtnModal link={getUrl(whatsappData.timoteo.phone, "Olá tudo bem?")} text="Timóteo" />
      </ModalContact>
    </section>
  );
};

export default About;