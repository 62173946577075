import React, { useState } from 'react';
import './css/styleCarouselOurUnitsImgsMob.scss';
import {
  CarouselItem,
  CarouselControl,
} from 'reactstrap';

function CarouselOurUnitsImgsMob({ content }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === content.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? content.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  return (
    <div className="carousel-units-imgs-mobile background-screens">
      {activeIndex !== 0 && (<CarouselControl
        direction="prev"
        onClickHandler={previous}
      />)}
      {activeIndex === content.length - 1 || (
        <CarouselControl
          direction="next"
          onClickHandler={next}
        />
      )}
      <div className="carousel-inner">
        {content.map((item, index) => (
          <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={index}
            className={ index === activeIndex ? 'carousel-item active' : 'carousel-item' }
          >
            <div
              className="carousel-image"
              style={{ backgroundImage: `url(${item.src})` }}
            />
          </CarouselItem>
        ))}
      </div>
    </div>
  );
}

export default CarouselOurUnitsImgsMob;
