import './style.scss';
import Header from '../../components/header';
import Main from '../../components/main';
import About from '../../components/About';
import Service from '../../components/service';
import Publication from '../../components/Publication-Insta';
import Team from '../../components/Team';
import Footer from '../../components/Footer';
import FeedbackSection from '../../components/Feedback-section';
import CarouselUnitsLocation from '../../components/Carousel-units-location';

function Home() {
  return (
  <>
    <Header/>
    <Main>
      <Service/>
      <About />
      <Team />
      <CarouselUnitsLocation/>
      <FeedbackSection/>
      <Publication/>
    </Main>
    <Footer />
  </>
  );
}

export default Home;