import React, { useEffect, useState } from 'react';
import './css/styleFeedback.scss';
import RatingDisplay from './rating-display-comp';



const FeedbackCompMobile = ({ item }) => {
  const [_windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='feedback-card-mobile d-flex flex-column'>
      <div className='content-container'>
        <div className='description-container d-flex flex-row'>
          <img className='image-feedback-card' src={ item.src} alt="" />
          <div className='name-and-rating'>
            <h3 className='name'><strong>{ item.name }</strong></h3>
            <RatingDisplay rating={ item.rating } w={ 8 } h={ 8 } />
          </div>
        </div>
        <p className='message text-start d-flex flex-column'>{item.message}</p>
      </div>
    </div>
  );
};

export default FeedbackCompMobile;
