import React from "react";
import { useRouteError } from "react-router-dom";
import "./errorPage.scss";

export default function ErrorPage() {
  const error = useRouteError();

  if (!error) {
    return (
      <div className="error-container">
        <div id="error-page">
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
        </div>
      </div>
    );
  }

  let errorMessage = "An unexpected error has occurred.";
  if (error && (error.statusText || error.message)) {
    errorMessage = error.statusText || error.message;
  }

  return (
    <div className="error-container">
      <div id="error-page">
        <h1>Oops!</h1>
        <p>{errorMessage}</p>
      </div>
    </div>
  );
}