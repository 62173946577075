import React, { useEffect, useState } from 'react';
import './css/styleCarouselOurUnitsLocs.scss';
import {
  CarouselItem,
  CarouselControl,
} from 'reactstrap';

import imgCarousel2 from '../assets/ourUnits/imageCarousel2.svg';

import LocationCard from './locationCard';
import CarouselOurUnitsImgs from './Carousel-ourunits-imgs';
import CarouselUnitsLocationMob from './Carousel-units-location-mob';
import imgLocTest from '../assets/ourUnits/locationTest.svg';
import loc1 from '../assets/ourUnits/loc1.png';
import loc2 from '../assets/ourUnits/loc2.png';

const itenst = [
  { title: 'Ipatinga', address: 'R. Jonh Kenedy, 106 - Cidade Nobre, Ipatinga - MG, 35162-380', status: 'Aberta 24h', buttonText: 'Entrar em contato', url: 'https://maps.app.goo.gl/Mh8ULjp4wQz2YK1M6', img: imgLocTest, phone: "5531986481032" },
  { title: 'Timóteo', address: 'Av. Juscelino Kubitscheck, 24 - uncionários, Timóteo - MG, 35180-410', status: 'Aberta 24h', buttonText: 'Entrar em contato', url: 'https://maps.app.goo.gl/i25WESt4tf7Yxx9v6', img: loc1, phone: "5531986481036" },
  { title: 'Coronel Fabriciano', address: 'AV. Gov. José de Mgalhães Pinto, 694 - Gionvanini, Cel. Fabriciano - MG, 35170-096', status: 'Aberta 24h', buttonText: 'Entrar em contato', url: 'https://maps.app.goo.gl/VPhiyYRZLZXzbrkQ6', img: loc2, phone: "5531993980498" },
];
const items = [
  { src: imgCarousel2, altText: 'Slide 2', caption: 'Slide 2' },
  { src: imgCarousel2, altText: 'Slide 2', caption: 'Slide 2' },
  { src: imgCarousel2, altText: 'Slide 2', caption: 'Slide 2' },
  { src: imgCarousel2, altText: 'Slide 2', caption: 'Slide 2' },
];

function CarouselUnitsLocation() {
  const [activeIndex, setActiveIndex] = useState(1);
  const [animating, setAnimating] = useState(false);
  const [carouselItens, setCarouselItens] = useState(itenst);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = (size, limit) => {
      return size < limit;
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === carouselItens.length - 2 ? 1 : activeIndex + 1;
    if (activeIndex === carouselItens.length - 2) {
      const firstItem = carouselItens[0];
      setCarouselItens([...carouselItens.filter((item, index) => index !== 0), firstItem])
    }
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 1 ? carouselItens.length - 2 : activeIndex - 1;
    if (activeIndex === 1) {
      const lastItem = carouselItens[carouselItens.length - 1];
      setCarouselItens([ lastItem, ...carouselItens.filter((item, index, arr) => index !== arr.length -1)])
    }
    setActiveIndex(nextIndex);
  };

  function getCarouselItemClasses(index) {
    if (index === activeIndex) {
      return 'carousel-item active';
    }

    if (index === activeIndex + 1 || index === activeIndex - 1) {
      return 'carousel-item faded';
    }

    return 'carousel-item';
  }

  if (isMobile(windowWidth, 880)) {
    return (<CarouselUnitsLocationMob itenst={ itenst } items={ items } />)
  }

  return (
    <section id='section-uni' className='section-our-units' style={ { width: '958px' } }>
      <h2 className='desktop-title'>Nossas Unidades</h2>
      <div className="carousel-units-locs">
        <div className="carousel-inner">
          {carouselItens.map((item, index) => (
              <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={index}
                className={getCarouselItemClasses(index)}
              >
                <LocationCard 
                title={item.title}
                address={item.address}
                status={item.status}
                buttonText={item.buttonText}
                url={item.url}
                img={item.img}
                phone={item.phone}
                />
              </CarouselItem>
            ))}
        </div>
        <CarouselControl
          direction="prev"
          onClickHandler={previous}
          className={''}
        />
        <CarouselControl
          direction="next"
          onClickHandler={() => next()}
          className={''}
        />
      </div>
      <CarouselOurUnitsImgs content={ items }
      />
    </section>
  );
}

export default CarouselUnitsLocation;
