import './style.scss';

const BtnModal = ({text, link}) => {
   return (
    <a className="link-contact" href={link}><button className="btn-modal-contact">
      {text}
    </button></a>
   )
}

export default BtnModal;