import './css/styleMain.scss'

function Main({children}) {
  return (
    <main className='container-main'>
      {children}
    </main>
  )
}

export default Main;