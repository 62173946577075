import React, { useState } from 'react';
import './css/styleCarouselOurUnitsImgs.scss';
import {
  CarouselItem,
  CarouselControl,
} from 'reactstrap';

function CarouselOurUnitsImgs({ content }) {
  const [activeIndex, setActiveIndex] = useState(1);
  const [animating, setAnimating] = useState(false);
  const [carouselItens, setCarouselItens] = useState(content);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === carouselItens.length - 2 ? 1 : activeIndex + 1;
    if (activeIndex === carouselItens.length - 2) {
      const firstItem = carouselItens[0];
      setCarouselItens([...carouselItens.filter((item, index) => index !== 0), firstItem])
    }
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 1 ? carouselItens.length - 2 : activeIndex - 1;
    if (activeIndex === 1) {
      const lastItem = carouselItens[carouselItens.length - 1];
      setCarouselItens([ lastItem, ...carouselItens.filter((item, index, arr) => index !== arr.length -1)])
    }
    setActiveIndex(nextIndex);
  };

  function getCarouselItemClasses(index) {
    if (index === activeIndex) {
      return 'carousel-item active';
    }

    if (index === activeIndex + 1 || index === activeIndex - 1) {
      return 'carousel-item faded';
    }

    return 'carousel-item';
  }

  return (
    <div className="carousel-units-imgs">
      <div className="carousel-inner">
        {carouselItens.map((item, index) => (
          <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={index}
            className={getCarouselItemClasses(index)}
          >
            <img src={item.src} alt={item.altText} style={{ borderRadius: '30px' }} />
          </CarouselItem>
        ))}
      </div>
      <CarouselControl
        direction="prev"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        onClickHandler={next}
      />
    </div>
  );
}

export default CarouselOurUnitsImgs;
